/* You can add global styles to this file, and also import other style files */
@import '~ng-zorro-antd/ng-zorro-antd.min.css';
@import '../node_modules/ngx-lightbox/lightbox.css';
@import '~swiper/scss';
@import '~swiper/scss/navigation';
@import '~swiper/scss/pagination';
@import 'driver.js/dist/driver.css';

.d-flex {
  display: flex !important;
}

.pos-relative {
  position: relative !important;
}

.pos-absolute {
  position: absolute !important;
}

.hide {
  display: none;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.w-100 {
  width: 100% !important;
}

.driver-popover {
  background-color: #378de5;
  color: #ffffff;

  .driver-popover-navigation-btns > .driver-popover-prev-btn, .driver-popover-next-btn {
    color: #378de5;
    font-weight: 600;
  }

  .driver-popover-close-btn {
    color: #ffffff;
  }

  .driver-popover-arrow {
    border: 5px solid #378de5;
  }

  .driver-popover-description {
    a {
      text-decoration: underline;
      color: #fff;
    }
  }

  .driver-popover-arrow-side-left {
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }

  .driver-popover-arrow-side-bottom {
    border-left-color: transparent;
    border-top-color: transparent;
    border-right-color: transparent;
  }

  .driver-popover-arrow-side-top {
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
  }

  .driver-popover-arrow-side-right {
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-color: transparent;
  }
}

:not(body):has(>.driver-active-element) {
  overflow: inherit !important;
}

.alert-modal-content {
  width: stretch !important;
  max-width: 800px !important;

  img {
    max-width: 100%;
  }
}

.ant-cascader-menu {
  height: 500px !important;
}

.text-green {
  color: green;
  font-weight: 600;
}

.img-thumb:hover {
  cursor: pointer;
}

strong {
  font-weight: 600 !important;
}

.ant-table-small {
  background: white;
}

.btn-calc {
  padding: 15px 10px 10px !important;
  background: white;
}

.ant-picker-input > input {
  text-align: center;
}

.ant-table-column-sorter-down {
  margin-top: 0 !important;
}

tr:nth-child(2n) {
  background-color: #f0f0f08e;
  border-bottom-color: black;
}

.ant-page-header-content,
.ant-page-header.has-breadcrumb {
  @media (max-width: 780px) {
    position: relative;
  }
}

.ant-table {
  overflow: auto;
}

.swiper {
  width: 100%;
  height: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.swiper-lot {
  height: 450px;
  margin: 0 auto;

  @media screen and (max-width: 576px) {
    height: 250px;
  }

  .swiper-slide img {
    width: auto;
    object-fit: contain;
  }

  .swiper-button-next,
  .swiper-button-prev {
    @media screen and (max-width: 576px) {
      display: none;
    }
  }
}

.ant-breadcrumb {
  & + .ant-page-header-heading {
    position: absolute;
    top: 10px;
    left: 25px;
    margin: 0 !important;
  }
}

.lot-button {
  min-width: 100px;

  &:visited {
    background: #10467c;
    border-color: #10467c;
  }
}

.lightbox .lb-image {
  animation: none;
}

nz-switch.green-corner-switch {
  .ant-switch {
    background-color: #bababa;
  }

  .ant-switch-checked {
    background-color: #4aab00;

    &:focus {
      box-shadow: 0 0 0 2px rgb(74, 171, 0, 0.2);
    }

    &:focus:hover {
      box-shadow: none;
    }
  }

  .ant-click-animating-node {
    animation: fade 2s cubic-bezier(0.08, 0.82, 0.17, 1),
    wave 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) !important;
  }

  @keyframes wave {
    100% {
      box-shadow: 0 0 0 6px #4aab00;
    }
  }
  @keyframes fade {
    100% {
      opacity: 0;
    }
  }
}

@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: inline-block !important;
  }
}

// $customButtonTypes: (
//   'won': #f8c262,
//   'confirmed': #c577f3,
//   'initial': #56a2f1,
//   'canceled': #565656,
//   'translated': #9aca2c,
// );

// @each $name, $color in $customButtonTypes {
//   .#{$name} {
//     color: $color;
//   }

//   .ant-btn[nztype='#{$name}'] {
//     color: #fff;
//     background: #{$color};
//     border-color: #{$color};
//     text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
//     box-shadow: 0 2px 0 rgb(0 0 0 / 5%);

//     &:hover,
//     &:focus {
//       background: lighten($color, 10%);
//       border-color: lighten($color, 10%);
//     }

//     &:active {
//       background: darken($color, 10%);
//       border-color: darken($color, 10%);
//     }
//   }

//   .ant-btn[nztype='#{$name}Outline'] {
//     color: $color;
//     background: #fff;
//     border-color: $color;

//     &:hover,
//     &:focus {
//       color: lighten($color, 10%);
//       border-color: lighten($color, 10%);
//     }

//     &:active {
//       color: darken($color, 10%);
//       border-color: darken($color, 10%);
//     }
//   }
// }

@keyframes pulse-blue {
  0% {
    box-shadow: 0 0 0 0 rgba(82, 167, 246, 0.7), 0 0 0 0 rgba(82, 167, 246, 0.7);
  }
  40% {
    box-shadow: 0 0 0 0 rgba(82, 167, 246, 0), 0 0 0 6px rgba(82, 167, 246, 0.7);
  }
  80% {
    box-shadow: 0 0 0 0 rgba(82, 167, 246, 0), 0 0 0 14px rgba(82, 167, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(82, 167, 246, 0.7), 0 0 0 6px rgba(82, 167, 246, 0);
  }
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(225, 50, 50, 0.7), 0 0 0 0 rgba(225, 50, 50, 0.7);
  }
  40% {
    box-shadow: 0 0 0 0 rgba(225, 50, 50, 0), 0 0 0 6px rgba(225, 50, 50, 0.7);
  }
  80% {
    box-shadow: 0 0 0 0 rgba(225, 50, 50, 0), 0 0 0 14px rgba(225, 50, 50, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(225, 50, 50, 0.7), 0 0 0 6px rgba(225, 50, 50, 0);
  }
}

@media screen and (max-width: 787px) {
  .auction-tip-modal {
    .ant-modal-body {
      padding: 5px!important;
    }
  }
}

.auction-tip-block-tooltip {
  .ant-tooltip-inner {
    border-radius: 8px;
    background: #13303c;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 15px;
    line-height: 1.6;

    div.header {
      margin-bottom: 5px;
      color: #fff;
      font-size: 15px;
    }

    p {
      margin: 0;
    }

    a {
      color: #378de5;
    }
  }
}
